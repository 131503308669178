import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { PageContent, Heading, Paragraph, Grid, Box } from "grommet";

import Page from "../components/page";
import SEO from "../components/seo";

const Title = styled(Paragraph)`
  text-decoration: underline;
  text-align: center;
`;

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Les infrastuctures | Les écuries du cerf"
        description="Les infrastructures des écuries du cerf, écuries de propriétaires, pensions pour chevaux à Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'eure 27"
      />
      <PageContent>
        <Heading alignSelf="center">Infrastructures</Heading>
        <Grid columns={"1/2"} gap="small" margin={{ top: "large" }}>
          <Box>
            <Title>Une carrière</Title>
            <Paragraph textAlign="center">
              50x40 mètres
              <br />
              Eclairée et encadrée de lisses en bois
            </Paragraph>
          </Box>
          <Box height="">
            <StaticImage
              objectFit="cover"
              src="../images/carriere.jpg"
              alt="La carriere des écuries du cerf"
            />
          </Box>
          <Box>
            <StaticImage
              objectFit="cover"
              src="../images/manege.jpg"
              alt="Les paddocks des écuries du cerf"
            />
          </Box>
          <Box>
            <Title>Le manège</Title>
            <Paragraph textAlign="center">
              30x15 mètres
              <br />
              Eclairé
            </Paragraph>
          </Box>
          <Box justify="center">
            <Title>Marcheur 4 places</Title>
          </Box>
          <Box height="">
            <StaticImage
              objectFit="cover"
              src="../images/rondDeMarche.jpg"
              alt="Le rond de marche des écuries du cerf"
            />
          </Box>
          <Box>
            <StaticImage
              objectFit="cover"
              src="../images/box.jpg"
              alt="Les box des écuries du cerf"
            />
          </Box>
          <Box justify="center">
            <Title>Box</Title>
          </Box>
          <Box justify="center">
            <Title>Deux salles de soins éclairées</Title>
          </Box>
          <Box height="">
            <StaticImage
              aspectRatio={0.66}
              objectFit="cover"
              src="../images/salle-de-soin.jpg"
              alt="Une salle de soins"
            />
          </Box>
          <Box>
            <StaticImage
              aspectRatio={0.66}
              objectFit="cover"
              src="../images/solarium.jpg"
              alt="Un solarium des écuries du cerf"
            />
          </Box>
          <Box justify="center">
            <Title>Deux solariums</Title>
          </Box>
        </Grid>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
